/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I like to cook very minimal meals and found myself eating the same things over and over: rice, beans, pasta, steamed vegetables. Pretty basic, but also healthy. Anyway, as a natural side effect of being a programmer, I thought abstractly about how all these things are prepared and realized they’re roughly all the same:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Measure dry ingredients"), "\n", React.createElement(_components.li, null, "Measure water"), "\n"), "\n", React.createElement(_components.p, null, "Then either:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Boil the water"), "\n", React.createElement(_components.li, null, "Add ingredients"), "\n"), "\n", React.createElement(_components.p, null, "or:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Rinse ingredients in water"), "\n", React.createElement(_components.li, null, "Add ingredients and water together and then boil"), "\n"), "\n", React.createElement(_components.p, null, "Pretty much the same thing over and over again. Why not automate it?"), "\n", React.createElement(_components.p, null, "The overall idea is to control the boiling with a hot plate on a timer via an arduino or raspberry pi\nand dispense the ingredients via a predetermined number of rotations of one of these:"), "\n", React.createElement("img", {
    src: "https://www.yankodesign.com/images/design_news/2009/10/27/smartspace.jpg",
    height: "234",
    width: "235",
    alt: "food dispenser"
  }), "\n", React.createElement(_components.p, null, "You’d also need a pump to control the water, and a couple of motors with multiple containers to rinse the ingredients and to mix them if needed."), "\n", React.createElement(_components.p, null, "You could then hookup a web server to it and control it remotely, for example, starting it when you leave work\nand have the ingredients of a meal waiting for you when you get home!"), "\n", React.createElement(_components.p, null, "It would definitely be a lot of effort, but I imagine some industrial individuals could make a self contained\nappliance that works for any water-plus-ingredients food."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
